<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <div class="text-left" @submit="saveForm">

                <h5 class="border-bottom pb-2 text-primary">{{ application.id !== undefined ? "Edit" : "Create"}} application users <sup class="text-dark">({{ application.ref }})</sup></h5>

                <b-form-group v-if="this.$store.getters.isSupervisor || this.$store.getters.isAdmin"
                              label-size="sm"
                              label="Assign to these users:">
                    <div v-if="isLoadingUsers" class="d-flex align-items-center border p-2 rounded">
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        Loading users
                    </div>
                    <div v-else>
                        <v-select
                                ref="userSelect"
                                label="name"
                                v-model="application.user_ids"
                                :multiple="true"
                                :reduce="option => option.value"
                                :options="users"
                                :filterable="true"
                                placeholder="Search for user">
                        </v-select>
                        <div class="mt-2 text-right">
                            <b-button :pill="true" variant="secondary" size="sm" @click="deselectUser">Deselect
                                all
                            </b-button>
                            <b-button :pill="true" variant="primary" class="ml-1" size="sm"
                                      @click="selectAllUsers">Select all
                            </b-button>
                        </div>
                    </div>

                    <error-display v-model="errors" ident="supervisor_ids"></error-display>
                </b-form-group>


                <input type="hidden" v-if="application.id != null"
                       v-model="application.id"/>
                <b-button @click="saveForm" variant="success">Save</b-button>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src

    import 'vue-simple-suggest/dist/styles.css'
    import {getResource, saveResource} from "../../modules/api/methods";
    import {applicationDetail, applicationUsers, baseApi, usersDropdown} from "../../modules/api/endpoints";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import {containsErrors} from "../../modules/helpers/helpers";
    import vSelect from "vue-select"

    export default {
        name: 'ApplicationUsersEdit',
        data: function () {
            return {
                isLoadingUsers: false,
                users : [],
                errorLoading : false,
                isLoading: false,
                application: {},
                nationalities: [],
                tags: [],
                errors: {},
                autoCompleteStyle: {
                    vueSimpleSuggest: "position-relative",
                    inputWrapper: "",
                    defaultInput: "form-control",
                    suggestions: "position-absolute list-group z-1000",
                    suggestItem: "list-group-item"
                },
                apiEndpoint: baseApi() + 'users/dropdown?roles=user'
            }
        },
        props: {
            applicationId: null
        },
        components: {
            "v-select": vSelect,
            ErrorDisplay,
        },
        created() {
            this.loadForm();
            this.loadAllUsers();
        },
        methods: {
            selectAllUsers() {
                this.application.user_ids = _.map(this.users, 'value');
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.select(option);
                });
                select.open = false
            },
            deselectUser() {
                this.application.user_ids = [];
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.deselect(option);
                });
                select.open = false
            },
            loadAllUsers() {
                this.isLoadingUsers = true;
                getResource(usersDropdown).then((resp) => {
                    this.users = resp.data.success;
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadAllUsers());
                }).finally(() => {
                    this.isLoadingUsers = false;
                });
            },
            saveForm() {
                this.errors = {};
                this.isLoading = true
                var postData = {};
                postData['user_ids'] = this.application.user_ids;
                console.log("USER IDS",postData);
                saveResource(applicationUsers(this.applicationId), postData).then(response => {
                    if(this.$router.currentRoute.name == 'applicationDetailUsersEdit') {
                        this.$router.push({'name': 'applicationDetail', params: {applicationId : this.applicationId}}).catch(error => {});
                    } else {
                        this.$router.push({'name': 'applications', params: {data_updated: true}}).catch(error => {});
                    }
                }).catch(error => {
                    if (containsErrors(error)) {
                        this.errors = error.data.errors;
                    } else {
                        this.$root.$children[0].handleApiError(error,this.saveForm)
                    }
                }).finally(() => {
                    this.isLoading = false
                });
            },
            loadForm() {
                this.isLoading = true;
                getResource(applicationDetail(this.applicationId)).then((res) => {
                    //PROCESS TAGS
                    this.application = res.data.success.data;
                    if (this.application.users !== undefined && this.application.users.length > 0) {
                        var userIds = [];
                        var userDropdown = [];
                        _.each(this.application.users, function (obj) {
                            userIds.push(obj.id)
                            userDropdown.push({value: obj.id, name: obj.name});
                        });
                        this.application.users_dropdown = userDropdown;
                        this.application.user_ids = userIds;
                    }
                    this.isLoading = false;
                    this.errorLoading = false;
                }).catch((error) => {
                    this.$root.$children[0].handleApiError(error,this.loadForm);
                    this.isLoading = false;
                    this.errorLoading = true;
                });
            }
        },
    }
</script>

